<template>
  <v-container fluid class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped>
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12" md="12">
        <h2>{{ titulo }}</h2>
        <v-divider />
      </v-col>
    </v-row>

    <v-card elevation="4" class="pa-10 mt-4">
      <!-- Inputs topo -->
      <v-row>
        <v-col v-if="!campoDesabilitado" cols="12" md="4">
          <v-text-field
            label="Link da tarefa no Jira *"
            v-model="processoCobrado.linkTarefaJira"
            class="tipo-numero mr-5"
            :rules="[regras.requerido(processoCobrado.linkTarefaJira)]"
            :readonly="campoDesabilitado"></v-text-field>
        </v-col>

        <v-col v-else cols="12" md="4" class="d-flex flex-column">
          <span class="fake-label">Link da tarefa no Jira *</span>
          <a
            target="_black"
            :href="processoCobrado.linkTarefaJira"
            class="mt-3">{{ processoCobrado.linkTarefaJira }}</a>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Código do cliente *"
            type="number"
            v-model.number="processoCobrado.codigoCliente"
            class="tipo-numero mr-5"
            :rules="[regras.requerido(processoCobrado.codigoCliente)]"
            :readonly="campoDesabilitado"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            label="Linha de produto *"
            auto-select-first
            v-model="processoCobrado.setorString"
            @change="recalcularValoresPorSetor"
            :items="setorItems"
            :readonly="campoDesabilitado"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            label="Tipo de aplicação *"
            auto-select-first
            v-model="processoCobrado.tipoDeAplicacaoString"
            :items="tipoAplicacaoItems"
            :readonly="campoDesabilitado"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            id="'quantidadeConexoes'"
            label="Quantidade de conexões (opcional)"
            hint="Quantidade de conexões simultâneas (se há recorrência)"
            @change="calcularAcrescimoRecorrencia"
            type="number"
            v-model.number="processoCobrado.quantidadeConexoes"
            class="tipo-numero mr-5"
            :readonly="campoDesabilitado" />
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            label="Status *"
            :disabled="novoProcessoProp"
            auto-select-first
            v-model="processoCobrado.statusString"
            :items="statusProcessoItems"
            :readonly="campoDesabilitado"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            outlined
            rows="4"
            auto-grow
            name="input-7-4"
            label="Justificativa"
            aria-placeholder="Justificativa"
            :readonly="campoDesabilitado"
            v-model="processoCobrado.justificativaStatus"
            v-if="
              !novoProcessoProp && processoCobrado.statusString === 'Reprovado'
            "></v-textarea>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            outlined
            rows="4"
            auto-grow
            name="input-7-4"
            label="Observação"
            aria-placeholder="Observação (Opcional)"
            hint="Observação (Opcional)"
            :readonly="campoDesabilitado"
            v-model="processoCobrado.observacao"></v-textarea>
        </v-col>
      </v-row>
      <!-- Estimativa de tempo -->
      <v-row>
        <v-col cols="12" md="12">
          <h2 class="titulo">Estimativa de tempo</h2>
        </v-col>
        <v-col cols="12" md="12">
          <v-card class="pa-3 elevation-1.5">
            <v-row>
              <v-col cols="12" md="6">
                <v-col cols="12" class="d-flex">
                  <h3 class="subtitulo mr-2">Programação:</h3>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="orange" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Bimer e Moda: mínimo 20 horas. Pack: mínimo 25 horas.
                      Shop: sem mínimo
                    </span>
                  </v-tooltip>
                </v-col>

                <v-col cols="12" class="d-flex">
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora Normal"
                    type="number"
                    v-model.number="horasTrabalhadasProgramacao.horaNormal"
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasProgramacao.horaNormal
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora 50%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasProgramacao.horaExtraCinquentaPorcento
                    "
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasProgramacao.horaExtraCinquentaPorcento
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora 100%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasProgramacao.horaExtraCemPorcento
                    "
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasProgramacao.horaExtraCemPorcento
                      )
                    ]" />
                </v-col>
              </v-col>
              <v-col cols="12" md="6">
                <v-col cols="12" class="d-flex">
                  <h3 class="subtitulo mr-2">Qualidade:</h3>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="orange" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Bimer e Moda: mínimo 10 horas. Pack: mínimo 20 horas.
                      Shop: sem mínimo
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora normal"
                    type="number"
                    v-model.number="horasTrabalhadasQualidade.horaNormal"
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasQualidade.horaNormal
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora 50%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasQualidade.horaExtraCinquentaPorcento
                    "
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasQualidade.horaExtraCinquentaPorcento
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero"
                    label="Hora 100%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasQualidade.horaExtraCemPorcento
                    "
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasQualidade.horaExtraCemPorcento
                      )
                    ]" />
                </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-col cols="12" class="d-flex">
                  <h3 class="subtitulo mr-2">Suporte:</h3>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="orange" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span> Mínimo 8 horas. </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora normal *"
                    type="number"
                    v-model.number="horasTrabalhadasSuporte.horaNormal"
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.valorMinimoHoraNormalSuporte(
                        horasTrabalhadasSuporte.horaNormal,
                        8
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora 50%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasSuporte.horaExtraCinquentaPorcento
                    "
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasSuporte.horaExtraCinquentaPorcento
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero"
                    label="Hora 100%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasSuporte.horaExtraCemPorcento
                    "
                    @change="recalcularValoresDinamicamente"
                    :readonly="campoDesabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasSuporte.horaExtraCemPorcento
                      )
                    ]" />
                </v-col>
              </v-col>

              <v-col cols="12" md="6">
                <v-col cols="12" class="d-flex">
                  <h3 class="subtitulo mr-2">Comercial:</h3>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="orange" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Bimer e Moda: mínimo 8 horas. Shop e Pack: sem mínimo.
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora normal"
                    type="number"
                    v-model.number="horasTrabalhadasComercial.horaNormal"
                    @change="
                      defineComissionamentoComercialRecalculaValoresDinamicamente
                    "
                    :readonly="campoDesabilitado"
                    :disabled="!comercialHabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasComercial.horaNormal
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero mr-10"
                    label="Hora 50%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasComercial.horaExtraCinquentaPorcento
                    "
                    @change="
                      defineComissionamentoComercialRecalculaValoresDinamicamente
                    "
                    :readonly="campoDesabilitado"
                    :disabled="!comercialHabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasComercial.horaExtraCinquentaPorcento
                      )
                    ]" />
                  <v-text-field
                    class="tipo-numero"
                    label="Hora 100%"
                    type="number"
                    v-model.number="
                      horasTrabalhadasComercial.horaExtraCemPorcento
                    "
                    @change="
                      defineComissionamentoComercialRecalculaValoresDinamicamente
                    "
                    :readonly="campoDesabilitado"
                    :disabled="!comercialHabilitado"
                    :rules="[
                      regras.numeroRequerido(
                        horasTrabalhadasComercial.horaExtraCemPorcento
                      )
                    ]" />
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Valor homem/hora -->
      <v-row>
        <v-col cols="12" md="12">
          <h2 class="titulo">Valor homem/hora</h2>
        </v-col>
        <v-col cols="12" md="12">
          <TabelaValorHoraHomem
            :valoresHomemHora="valorHomemHora.valoresHomemHora" />
        </v-col>
      </v-row>

      <!-- Custo operacional -->
      <v-row>
        <v-col cols="12" md="12">
          <h2 class="titulo">
            Custos operacionais sobre valor real cobrado do cliente
          </h2>
        </v-col>
        <v-col cols="12" md="12">
          <TabelaCustosOperacionais
            :impostos="impostoFixo.impostos"
            :custoTotalComHomemHora="processoCobrado.custoTotalComHomemHora"
            :valorMinimoCobranca="processoCobrado.valorMinimoParaCobranca" />
        </v-col>
      </v-row>

      <!-- Campos calculados -->
      <v-row class="mt-7" justify="space-around">
        <v-col cols="12" md="4" class="d-flex flex-column align-end">
          <h4 class="titulo">Custo total com homem/hora</h4>
          <span class="subtitulo">
            {{ aplicarMascaraDinheiro(processoCobrado.custoTotalComHomemHora) }}
          </span>
        </v-col>
        <v-col cols="12" md="4" class="d-flex flex-column align-end">
          <h4 class="titulo">Total despesas (sem IRPJ/CSLL/ADIC.IR)</h4>
          <span class="subtitulo">
            {{
                aplicarMascaraDinheiro(
                  processoCobrado.totalDespesasSemIrpjCsllAdicIrSobreLucro
                )
            }}
          </span>
        </v-col>
        <v-col cols="12" md="4" class="d-flex flex-column align-end">
          <h4 class="titulo">IRPJ/CSLL/ADIC.IR sobre Lucro</h4>
          <span class="subtitulo">
            {{
                aplicarMascaraDinheiro(processoCobrado.irpjCsllAdicIrSobreLucro)
            }}
          </span>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="12" md="8" class="d-flex flex-column align-end">
          <h3 class="titulo">Valor mínimo para recorrência</h3>
          <span class="dinheiro">
            {{
                aplicarMascaraDinheiro(processoCobrado.valorMinimoParaRecorrencia)
            }}
          </span>
        </v-col>
        <v-col cols="12" md="4" class="d-flex flex-column align-end">
          <h3 class="titulo">Valor mínimo para cobrança</h3>
          <span class="dinheiro">
            {{
                aplicarMascaraDinheiro(processoCobrado.valorMinimoParaCobranca)
            }}
          </span>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <!-- Botoes -->
      <v-row>
        <v-col cols="12" md="12" class="d-flex justify-end pa-0">
          <v-btn
            class="btn-primary elevation-1"
            v-show="campoDesabilitado && !processoPublico"
            @click="gerarLinkPublico"
            text>Gerar link</v-btn>
          <v-btn
            v-show="!campoDesabilitado"
            class="btn-primary elevation-1"
            @click="requisicaoProcessoCobrado"
            :disabled="botaoDesabilitado"
            text>{{ descricaoBotaoPrimario }}</v-btn>
          <v-btn
            v-show="!processoPublico"
            class="btn-tertiary elevation-1"
            text
            :disabled="botaoDesabilitado"
            @click="fecharFormulario">{{ descricaoBotaoSecundario }}</v-btn>
        </v-col>
      </v-row>

      <!-- Modal -->
      <v-dialog v-model="manipuladorModal" max-width="700px">
        <v-card class="pa-5">
          <div class="pa-8">
            <p class="title">Copie abaixo o link do processo</p>
            <p class="mb-8">
              <em>Esse link pode se acessado fora do sistema estando na VPN</em>
            </p>
            <a
              class="title"
              :href="urlPublica"
              target="_blank"
              rel="noopener noreferrer">{{ urlPublica }}</a>
          </div>
          <div class="d-flex justify-end">
            <v-btn
              class="btn-tertiary elevation-1"
              small
              text
              @click="manipuladorModal = false">
              Fechar
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import {
  SETOR,
  FUNCAO,
  TIPO_APLICACAO,
  STATUS_PROCESSO,
  NOME_IMPOSTO
} from "@/constants/geral-constants";
import {
  BIMER,
  MODA,
  IMMOBILE,
  PACK,
  SHOP
} from "@/constants/horas-minimas-por-setor-constants";

import apiValoresFixosImpostos from "@/api/valores-fixos-impostos/valores-fixos-impostos-api";
import apiValorHomemHora from "@/api/calculos-custo-homem/calculos-custo-homem-api";
import apiProcessoCobrado from "@/api/processo-cobrado/processo-cobrado-api";

import storage from "../../utils/storage";

import CalculosModel from "@/model/calculos-model";
import HorasTrabalhadasModel from "@/model/horas-trabalhadas-model";
import ImpostoFixoModel from "@/model/imposto-fixo-model";
import ValorHoraHomemModel from "@/model/valor-hora-homem-model";
import ProcessoCobradoModel from "@/model/processo-cobrado-model";

import TabelaValorHoraHomem from "@/components/tabela/TabelaValorHoraHomem.vue";
import TabelaCustosOperacionais from "@/components/tabela/TabelaCustosOperacionais.vue";

import mixinMensagen from "@/mixin/mixin-mensagem";

export default {
  name: "ProcessoCobrado",
  mixins: [mixinMensagen],
  components: {
    TabelaValorHoraHomem,
    TabelaCustosOperacionais
  },
  props: {
    titulo: { type: String, default: "" },
    descricaoBotaoPrimario: { type: String, default: "" },
    descricaoBotaoSecundario: { type: String, default: "" },
    campoDesabilitadoProp: { type: Boolean, default: false },
    novoProcessoProp: { type: Boolean, default: false },
    processoPublico: { type: Boolean, default: false }
  },
  data() {
    return {
      regras: {
        requerido: valor => !!valor || "Obrigatório!",
        numeroRequerido: valor => valor === 0 ? true : !!valor || "Valor mínimo 0 horas",
        valorMinimoHoraNormalSuporte(valor, min) {
          return (valor || "") >= min || `Valor mínimo ${min} horas!`;
        }
      },
      usuarioStorage: {},
      campoDesabilitado: false,
      botaoDesabilitado: false,
      comercialHabilitado: true,
      manipuladorModal: false,
      processoId: 0,
      statusProcessoItems: STATUS_PROCESSO.STATUS_PROCESSO_ITEMS,
      setorItems: SETOR.SETOR_ITEMS,
      tipoAplicacaoItems: TIPO_APLICACAO.TIPO_APLICACAO_ITEMS,
      urlPublica: undefined,
      calculos: new CalculosModel(),
      valorHomemHora: new ValorHoraHomemModel(),
      impostoFixo: new ImpostoFixoModel(),
      processoCobrado: new ProcessoCobradoModel(),
      processoCobradoClone: new ProcessoCobradoModel(),

      horasTrabalhadasProgramacao: new HorasTrabalhadasModel(),
      horasTrabalhadasQualidade: new HorasTrabalhadasModel(),
      horasTrabalhadasSuporte: new HorasTrabalhadasModel(),
      horasTrabalhadasComercial: new HorasTrabalhadasModel()
    };
  },
  async mounted() {
    if (this.novoProcessoProp) {
      this.usuarioStorage = JSON.parse(storage.obterUsuarioAtualStorage());
      this.processoCobrado.loginUsuario = this.usuarioStorage.login;
      this.processoCobrado.idSetor = this.usuarioStorage.setoresUsuario[0].id;
      this.processoCobrado.setorString = this.usuarioStorage.setoresUsuario[0].descricao;
      this.processoCobrado.tipoDeAplicacaoString = TIPO_APLICACAO.DESKTOP.VALUE;
      this.processoCobrado.statusString = STATUS_PROCESSO.PENDENTE.VALUE;

      this.popularHorasTrabalhadasPorSetor(
        this.usuarioStorage.setoresUsuario[0].descricao
      );

      await this.obterValorHomemHoraPorSetor();
      await this.obterUltimoRegistroValoresFixosImpostos();
    } else {
      await this.popularProcessoCobradoExistente();
    }

    this.recalcularValoresDinamicamente();
    this.habilitarDesabilitarCampoComercial();
  },
  methods: {
    aplicarMascaraDinheiro(valor) {
      valor = valor || 0;

      return Number(valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    },

    async obterUltimoRegistroValoresFixosImpostos() {
      try {
        const responseImpostoFixo = await apiValoresFixosImpostos.obterUltimoRegistroValoresFixosImpostos();

        this.impostoFixo = new ImpostoFixoModel(responseImpostoFixo.data);
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
          "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
    },

    async obterImpostosFixosPorId(id) {
      try {
        const responseImpostoFixo = await apiValoresFixosImpostos.obter(id);

        this.impostoFixo = new ImpostoFixoModel(responseImpostoFixo.data);
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
          "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
    },

    async obterValorHomemHoraPorSetor() {
      try {
        const responseValorHoraHomem = await apiValorHomemHora.obterValorHomemHoraPorSetor(
          this.processoCobrado.idSetor
        );

        this.valorHomemHora = new ValorHoraHomemModel(
          responseValorHoraHomem.data
        );
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
          "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
    },

    async obterProcessoCobrado(id) {
      try {
        const responseProcessoCobrado = await apiProcessoCobrado
          .obterProcessoCobradoPorId(id)
          .then(response => response)
          .catch(error => error);

        return responseProcessoCobrado.data;
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
          "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
    },

    async popularProcessoCobradoExistente() {
      if (this.campoDesabilitadoProp) {
        this.campoDesabilitado = this.campoDesabilitadoProp;
      }

      this.processoId = this.$route.params.id;

      this.processoCobrado = new ProcessoCobradoModel(
        await this.obterProcessoCobrado(this.processoId)
      );

      this.processoCobradoClone = new ProcessoCobradoModel(
        this.processoCobrado
      );

      this.popularHorasTrabalhadasNoProcessoCobrado(this.processoCobrado);

      this.popularValorHomemHora(
        this.processoCobrado.valorHoraHomemProgramacao,
        this.processoCobrado.valorHoraHomemQualidade,
        this.processoCobrado.valorHoraHomemSuporte,
        this.processoCobrado.valorHoraHomemComercial
      );

      await this.obterImpostosFixosPorId(
        this.processoCobrado.idValoresFixosImpostos
      );
    },

    popularValorHomemHora(programador, qualidade, suporte, comercial) {
      this.valorHomemHora = new ValorHoraHomemModel({
        programador,
        qualidade,
        suporte,
        comercial
      });
    },

    popularHorasTrabalhadas(programador, qualidade, suporte, comercial) {
      this.horasTrabalhadasProgramacao = new HorasTrabalhadasModel(programador);
      this.horasTrabalhadasQualidade = new HorasTrabalhadasModel(qualidade);
      this.horasTrabalhadasSuporte = new HorasTrabalhadasModel(suporte);
      this.horasTrabalhadasComercial = new HorasTrabalhadasModel(comercial);
    },

    popularHorasTrabalhadasNoProcessoCobrado(processoCobrado) {
      this.horasTrabalhadasProgramacao = new HorasTrabalhadasModel({
        funcao: FUNCAO.PROGRAMADOR.VALUE,
        horaNormal: processoCobrado.horasTrabalhadasProgramacaoHoraNormal,
        horaExtraCinquentaPorcento:
          processoCobrado.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento,
        horaExtraCemPorcento:
          processoCobrado.horasTrabalhadasProgramacaoHoraExtraCemPorCento
      });

      this.horasTrabalhadasQualidade = new HorasTrabalhadasModel({
        funcao: FUNCAO.QUALIDADE.VALUE,
        horaNormal: processoCobrado.horasTrabalhadasQualidadeHoraNormal,
        horaExtraCinquentaPorcento:
          processoCobrado.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento,
        horaExtraCemPorcento:
          processoCobrado.horasTrabalhadasQualidadeHoraExtraCemPorCento
      });

      this.horasTrabalhadasSuporte = new HorasTrabalhadasModel({
        funcao: FUNCAO.SUPORTE.VALUE,
        horaNormal: processoCobrado.horasTrabalhadasSuporteHoraNormal,
        horaExtraCinquentaPorcento:
          processoCobrado.horasTrabalhadasSuporteHoraExtraCinquentaPorCento,
        horaExtraCemPorcento:
          processoCobrado.horasTrabalhadasSuporteHoraExtraCemPorCento
      });

      this.horasTrabalhadasComercial = new HorasTrabalhadasModel({
        funcao: FUNCAO.COMERCIAL.VALUE,
        horaNormal: processoCobrado.horasTrabalhadasComercialHoraNormal,
        horaExtraCinquentaPorcento:
          processoCobrado.horasTrabalhadasComercialHoraExtraCinquentaPorCento,
        horaExtraCemPorcento:
          processoCobrado.horasTrabalhadasComercialHoraExtraCemPorCento
      });

      this.calculos = new CalculosModel([
        this.horasTrabalhadasProgramacao,
        this.horasTrabalhadasQualidade,
        this.horasTrabalhadasSuporte,
        this.horasTrabalhadasComercial
      ]);
    },

    popularHorasTrabalhadasPorSetor(setor) {
      switch (setor) {
        case SETOR.BIMER.VALUE:
          this.popularHorasTrabalhadas(
            BIMER.PROGRAMADOR,
            BIMER.QUALIDADE,
            BIMER.SUPORTE,
            BIMER.COMERCIAL
          );
          break;
        case SETOR.IMMOBILE.VALUE:
          this.popularHorasTrabalhadas(
            IMMOBILE.PROGRAMADOR,
            IMMOBILE.QUALIDADE,
            IMMOBILE.SUPORTE,
            IMMOBILE.COMERCIAL
          );
          break;
        case SETOR.MODA.VALUE:
          this.popularHorasTrabalhadas(
            MODA.PROGRAMADOR,
            MODA.QUALIDADE,
            MODA.SUPORTE,
            MODA.COMERCIAL
          );
          break;
        case SETOR.PACK.VALUE:
          this.popularHorasTrabalhadas(
            PACK.PROGRAMADOR,
            PACK.QUALIDADE,
            PACK.SUPORTE,
            PACK.COMERCIAL
          );
          break;
        case SETOR.SHOP.VALUE:
          this.popularHorasTrabalhadas(
            SHOP.PROGRAMADOR,
            SHOP.QUALIDADE,
            SHOP.SUPORTE,
            SHOP.COMERCIAL
          );
          break;
      }
    },

    habilitarDesabilitarCampoComercial() {
      this.comercialHabilitado = true;

      if (this.processoCobrado.setorString === SETOR.SHOP.VALUE) {
        this.comercialHabilitado = false;

        Object.keys(this.horasTrabalhadasComercial).forEach(key => {
          // Ignora a key funcao para zerar as horas trabalhadas.
          if (this.horasTrabalhadasComercial[key] !== FUNCAO.COMERCIAL.VALUE) {
            this.horasTrabalhadasComercial[key] = 0;
          }
        });
      }
    },

    calcularCustoTotalComHomemHora() {
      this.processoCobrado.custoTotalComHomemHora = this.calculos.valorCustoTotalComHomemHora(
        this.valorHomemHora
      );
    },

    calcularValorMinimoParaCobranca() {
      this.processoCobrado.valorMinimoParaCobranca = this.calculos.valorMinimoParaCobranca(
        this.impostoFixo,
        this.valorHomemHora
      );
    },

    calcularAcrescimoRecorrencia() {
      this.processoCobrado.valorMinimoParaRecorrencia = parseFloat(
        (
          this.valorHomemHora.suporte *
          this.processoCobrado.quantidadeConexoes || 0
        ).toFixed(2)
      );
    },

    calcularTotalDespesasSemIrpj() {
      this.processoCobrado.totalDespesasSemIrpjCsllAdicIrSobreLucro = this.impostoFixo.calcularTotalDespesas(
        this.processoCobrado.custoTotalComHomemHora,
        this.processoCobrado.valorMinimoParaCobranca,
        [this.horasTrabalhadasComercial]
      );
    },

    calcularIrpjCsllAdicIrSobreLucro() {
      this.processoCobrado.irpjCsllAdicIrSobreLucro = this.impostoFixo.calcularIrpjCsllAdicIrSobreLucro(
        this.processoCobrado.valorMinimoParaCobranca,
        this.processoCobrado.totalDespesasSemIrpjCsllAdicIrSobreLucro
      );
    },

    defineComissionamentoComercialRecalculaValoresDinamicamente() {
      if (this.impostoFixo.comissionamentoComercial == 0) {
        this.impostoFixo.comissionamentoComercial = this.impostoFixo.comissionamentoComercialReal;
        this.impostoFixo = new ImpostoFixoModel(this.impostoFixo);
      }

      this.recalcularValoresDinamicamente();
    },

    recalcularValoresDinamicamente() {
      this.calculos = new CalculosModel([
        this.horasTrabalhadasProgramacao,
        this.horasTrabalhadasQualidade,
        this.horasTrabalhadasSuporte,
        this.horasTrabalhadasComercial
      ]);

      this.calcularCustoTotalComHomemHora();
      this.calcularValorMinimoParaCobranca();
      this.calcularTotalDespesasSemIrpj();
      this.calcularIrpjCsllAdicIrSobreLucro();
      this.calcularAcrescimoRecorrencia();
    },

    async recalcularValoresPorSetor() {
      this.processoCobrado.idSetor =
        SETOR[this.processoCobrado.setorString.toUpperCase()].ID;

      await this.obterValorHomemHoraPorSetor();

      if (this.novoProcessoProp) {
        this.popularHorasTrabalhadasPorSetor(this.processoCobrado.setorString);
      }
      this.habilitarDesabilitarCampoComercial();
      this.defineComissionamentoComercialRecalculaValoresDinamicamente();
    },

    obterHoraTrabalhadas() {
      this.processoCobrado.horasTrabalhadasProgramacaoHoraNormal =
        this.horasTrabalhadasProgramacao.horaNormal;

      this.processoCobrado.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento =
        this.horasTrabalhadasProgramacao.horaExtraCinquentaPorcento;

      this.processoCobrado.horasTrabalhadasProgramacaoHoraExtraCemPorCento =
        this.horasTrabalhadasProgramacao.horaExtraCemPorcento;

      this.processoCobrado.horasTrabalhadasQualidadeHoraNormal =
        this.horasTrabalhadasQualidade.horaNormal;

      this.processoCobrado.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento =
        this.horasTrabalhadasQualidade.horaExtraCinquentaPorcento;

      this.processoCobrado.horasTrabalhadasQualidadeHoraExtraCemPorCento =
        this.horasTrabalhadasQualidade.horaExtraCemPorcento;

      this.processoCobrado.horasTrabalhadasSuporteHoraNormal =
        this.horasTrabalhadasSuporte.horaNormal;

      this.processoCobrado.horasTrabalhadasSuporteHoraExtraCinquentaPorCento =
        this.horasTrabalhadasSuporte.horaExtraCinquentaPorcento;

      this.processoCobrado.horasTrabalhadasSuporteHoraExtraCemPorCento =
        this.horasTrabalhadasSuporte.horaExtraCemPorcento;

      this.processoCobrado.horasTrabalhadasComercialHoraNormal =
        this.horasTrabalhadasComercial.horaNormal;

      this.processoCobrado.horasTrabalhadasComercialHoraExtraCinquentaPorCento =
        this.horasTrabalhadasComercial.horaExtraCinquentaPorcento;

      this.processoCobrado.horasTrabalhadasComercialHoraExtraCemPorCento =
        this.horasTrabalhadasComercial.horaExtraCemPorcento;
    },

    obterValorImpostosCalculados() {
      this.processoCobrado.pisSobreFaturamento = this.impostoFixo.impostos
        .find(i => i.descricao === NOME_IMPOSTO.PISSFATURAMENTO.VALUE)
        .calcularImpostos(this.processoCobrado.valorMinimoParaCobranca);

      this.processoCobrado.cofinsSobreFaturamento = this.impostoFixo.impostos
        .find(i => i.descricao === NOME_IMPOSTO.CONFISSOBREFATURAMENTO.VALUE)
        .calcularImpostos(this.processoCobrado.valorMinimoParaCobranca);

      this.processoCobrado.issSobreFaturamento = this.impostoFixo.impostos
        .find(i => i.descricao === NOME_IMPOSTO.ISSSOBREFATURAMENTO.VALUE)
        .calcularImpostos(this.processoCobrado.valorMinimoParaCobranca);

      this.processoCobrado.inssSobreDesoneracao = this.impostoFixo.impostos
        .find(i => i.descricao === NOME_IMPOSTO.INSSSOBREDESONERACAO.VALUE)
        .calcularImpostos(this.processoCobrado.valorMinimoParaCobranca);

      this.processoCobrado.custosAdministrativos = this.impostoFixo.impostos
        .find(i => i.descricao === NOME_IMPOSTO.CUSTOSADMINISTRATIVOS.VALUE)
        .calcularImpostoDeCustosAdministrativos(
          this.processoCobrado.custoTotalComHomemHora
        );

      this.processoCobrado.comissionamentoComercial = this.impostoFixo.impostos
        .find(i => i.descricao === NOME_IMPOSTO.COMISSIONAMENTOCOMERCIAL.VALUE)
        .calcularImpostos(this.processoCobrado.valorMinimoParaCobranca);
    },

    requisicaoProcessoCobrado() {
      this.processoCobrado.tipoDeAplicacao =
        TIPO_APLICACAO[
          this.processoCobrado.tipoDeAplicacaoString.toUpperCase()
        ].ID;
      this.processoCobrado.idValoresFixosImpostos = this.impostoFixo.id;
      this.processoCobrado.idStatus =
        STATUS_PROCESSO[this.processoCobrado.statusString.toUpperCase()].ID;
      this.processoCobrado.valorHoraHomemProgramacao = this.valorHomemHora.programador;
      this.processoCobrado.valorHoraHomemQualidade = this.valorHomemHora.qualidade;
      this.processoCobrado.valorHoraHomemSuporte = this.valorHomemHora.suporte;
      this.processoCobrado.valorHoraHomemComercial = this.valorHomemHora.comercial;

      this.obterValorImpostosCalculados();
      this.obterHoraTrabalhadas();

      if (!this.processoCobrado.modeloValido()) {
        this.dispararMensagem(
          "Você não preencheu todos os campos obrigatórios.",
          "error"
        );
        return;
      }

      if (this.novoProcessoProp) {
        this.cadastrarProcessoCobrado(this.processoCobrado);
      } else {
        this.verificarAlteracoesNoProcessoParaEditar();
      }
    },

    cadastrarProcessoCobrado(processoCobrado) {
      apiProcessoCobrado
        .cadastrarProcessoCobrado(processoCobrado)
        .then(() => {
          this.dispararMensagem("Processo cadastrado com sucesso.", "success");
          this.zerarFormulario();
        })
        .catch(error => {
          this.dispararMensagem(
            error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
            "error"
          );
        });
    },

    editarProcessoCobrado(processoCobrado, id) {
      apiProcessoCobrado
        .editarProcessoCobrado(processoCobrado, id)
        .then(() => {
          this.botaoDesabilitado = true;
          this.dispararMensagem("Processo editado com sucesso.", "success");
          setTimeout(() => {
            this.fecharFormulario();
          }, 1500);
        })
        .catch(error => {
          this.dispararMensagem(
            error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
            "error"
          );
        });
    },

    verificarAlteracoesNoProcessoParaEditar() {
      let valorComparado = [];

      for (const chave in this.processoCobrado) {
        if (
          chave !== "dataHoraCadastro" &&
          chave !== "dataHoraCadastroSemFormatar"
        ) {
          if (
            this.processoCobradoClone[chave] !== this.processoCobrado[chave]
          ) {
            valorComparado.push(chave);
          }
        }
      }

      if (valorComparado.length !== 0) {
        this.editarProcessoCobrado(
          this.processoCobrado,
          this.processoCobrado.id
        );
      } else {
        this.dispararMensagem(
          "Para editar um processo é necessário alterar algum valor!",
          "error"
        );
      }
    },

    gerarLinkPublico() {
      this.urlPublica = `${process.env.VUE_APP_DOMINIO_URL}/processos/visualizar/${this.processoCobrado.id}/publico`;
      this.manipuladorModal = true;
    },

    zerarFormulario() {
      this.processoCobrado = new ProcessoCobradoModel();
      this.processoCobrado.idSetor = this.usuarioStorage.setoresUsuario[0].id;
      this.processoCobrado.setorString = this.usuarioStorage.setoresUsuario[0].descricao;
      this.processoCobrado.tipoDeAplicacaoString = TIPO_APLICACAO.DESKTOP.VALUE;
      this.processoCobrado.statusString = STATUS_PROCESSO.PENDENTE.VALUE;

      this.habilitarDesabilitarCampoComercial();
      this.popularHorasTrabalhadasPorSetor(
        this.usuarioStorage.setoresUsuario[0].descricao
      );
      this.recalcularValoresDinamicamente();
    },

    fecharFormulario() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.dinheiro {
  font-weight: 600;
  font-size: 1.4rem;
}

.fake-label {
  font-size: 12px;
  color: $grey-600;
}
</style>
