const FUNCAO = {
  PROGRAMADOR: {
    ID: 0,
    VALUE: "programador"
  },
  SUPORTE: {
    ID: 1,
    VALUE: "suporte"
  },
  QUALIDADE: {
    ID: 2,
    VALUE: "qualidade"
  },
  COMERCIAL: {
    ID: 3,
    VALUE: "comercial"
  },
  FUNCAO_ITEMS: ["Programador", "Qualidade", "Suporte", "Comercial"]
};

const SETOR = {
  BIMER: {
    ID: 1,
    VALUE: "Bimer"
  },
  SHOP: {
    ID: 2,
    VALUE: "Shop"
  },
  PACK: {
    ID: 3,
    VALUE: "Pack"
  },
  IMMOBILE: {
    ID: 4,
    VALUE: "Immobile"
  },
  MODA: {
    ID: 5,
    VALUE: "Moda"
  },
  SETOR_ITEMS: ["Bimer", "Immobile", "Moda", "Pack", "Shop"],
  SETOR_COM_COMERCIAL_ITEMS: ["Bimer", "Moda","Immobile", "Pack"]
};

const PERFIL_USUARIO = {
  ADMIN: {
    ID: 1,
    VALUE: "Admin"
  },
  MANAGER: {
    ID: 2,
    VALUE: "Manager"
  },
  STANDARD: {
    VALUE: "Standard",
    ID: 3
  },
  BOARD: {
    VALUE: "Board",
    ID: 4
  },
  PERFIL_ITEMS: ["Admin", "Board", "Manager", "Standard"]
};

const STATUS_USUARIO = {
  ATIVO: {
    ID: 0,
    VALUE: "Ativo"
  },
  INATIVO: {
    ID: 1,
    VALUE: "Inativo"
  },
  STATUS_ITEMS: ["Ativo", "Inativo"]
};

const ACAO_MODAL = {
  ATUALIZAR: {
    ID: 1
  },
  CADASTRAR: {
    ID: 2
  },
  INATIVAR: {
    ID: 3
  },
  REATIVAR: {
    ID: 4
  }
};

const TIPO_APLICACAO = {
  DESKTOP: {
    ID: 1,
    VALUE: "Desktop"
  },
  MOBILE: {
    ID: 2,
    VALUE: "Mobile"
  },
  WEB: {
    ID: 3,
    VALUE: "Web"
  },
  TIPO_APLICACAO_ITEMS: ["Desktop", "Mobile", "Web"]
};

const STATUS_PROCESSO = {
  APROVADO: {
    ID: 1,
    VALUE: "Aprovado"
  },
  CANCELADO: {
    ID: 2,
    VALUE: "Cancelado"
  },
  PENDENTE: {
    ID: 3,
    VALUE: "Pendente"
  },
  REPROVADO: {
    ID: 4,
    VALUE: "Reprovado"
  },
  STATUS_PROCESSO_ITEMS: ["Aprovado", "Cancelado", "Pendente", "Reprovado"]
};

const NOME_IMPOSTO = {
  PISSFATURAMENTO: {
    VALUE: "Pis sobre faturamento"
  },
  CONFISSOBREFATURAMENTO: {
    VALUE: "Cofins sobre faturamento"
  },
  ISSSOBREFATURAMENTO: {
    VALUE: "ISS sobre faturamento"
  },
  INSSSOBREDESONERACAO: {
    VALUE: "INSS desoneração"
  },
  CUSTOSADMINISTRATIVOS: {
    VALUE: "Custos administrativos"
  },
  COMISSIONAMENTOCOMERCIAL: {
    VALUE: "Comissionamento comercial"
  }
};

const TIPO_DE_LOG = [
  {
    ID: 0,
    VALUE: "Create"
  },
  {
    ID: 1,
    VALUE: "Delete"
  },
  {
    ID: 2,
    VALUE: "Update"
  }
];

const MES_ITEMS = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
];

const TIPO_FILTRO = {
  HOJE: {
    DATA_INICIAL: `${new Date()}`,
    DATA_FINAL: `${new Date()}`
  },
  ULTIMASEMANA: {
    DATA_INICIAL: `${new Date(new Date().setDate(new Date().getDate() - 7))}`,
    DATA_FINAL: `${new Date()}`
  },
  ESTEMES: {
    DATA_INICIAL: `${new Date(new Date().setDate(1))}`,
    DATA_FINAL: `${new Date()}`
  },
  ULTIMOSTRINTADIAS: {
    DATA_INICIAL: `${new Date(new Date().setDate(new Date().getDate() - 30))}`,
    DATA_FINAL: `${new Date()}`
  },
  ULTIMOSTRESMESES: {
    DATA_INICIAL: `${new Date(new Date().setDate(new Date().getDate() - 90))}`,
    DATA_FINAL: `${new Date()}`
  },
  TODOS: {
    DATA_INICIAL: null,
    DATA_FINAL: null
  },
  TIPO_FILTRO_ITEMS: [
    "Hoje",
    "Última semana",
    "Este mês",
    "Últimos trinta dias",
    "Últimos três meses",
    "Todos"
  ]
};

export {
  FUNCAO,
  SETOR,
  PERFIL_USUARIO,
  STATUS_USUARIO,
  ACAO_MODAL,
  TIPO_APLICACAO,
  STATUS_PROCESSO,
  TIPO_DE_LOG,
  NOME_IMPOSTO,
  MES_ITEMS,
  TIPO_FILTRO
};
